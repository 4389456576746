<template>
  <SimpleLayout>
    <el-card style="min-height: 30rem">
      <el-table border :data="list">
        <el-table-column prop="name" width="100" label="名称"></el-table-column>
        <el-table-column prop="path" label="路径"></el-table-column>
        <el-table-column label="操作" width="200">
          <template #default="s">
            <el-button @click="copyLink(s.row.path)">复制路径</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </SimpleLayout>
</template>

<script>

import SimpleLayout from "@/components/layouts/SimpleLayout";
export default {
  name: "entry",
  components: {SimpleLayout},
  data(){
    return{
      list:[
        {name:"首页",path:"/pages/index/index"}
      ],
      uni_acid : 0,
    }
  },
  mounted() {
    this.uni_acid = this.vuex_active_uniacid;
  },
  methods:{
    copyLink(link){
      this.$copyText(link).then(()=>{
        this.$message.success("复制成功")
      })
    }
  }
}
</script>

<style scoped>

</style>